<template>
  <div>
    <section class="section is-main-section" :class="{'p-0': modal}">
      <card-component :title="form.id ? `${form.name} Düzenle` : 'Rol Oluştur'" icon="domain">
        <form @submit.prevent="save">
          <b-field label="İsim">
            <b-input size="is-small" v-model="form.name" icon="domain" placeholder="İsim" name="name" required/>
          </b-field>
          <b-field label="Yetkiler">
            <treeselect :multiple="true" :always-open="false" :auto-deselect-descendants="true"
                        :auto-select-descendants="true" :flat="true" :options="permissions"
                        value-consists-of="BRANCH_PRIORITY" v-model="form.permissions"/>
          </b-field>
          <hr class="mb-6"/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button native-type="submit" type="is-primary" size="is-small">
                {{ form.id ? 'Güncelle' : 'Ekle' }}
              </b-button>
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" size="is-small" @click="modal ? $emit('close') : $router.go(-1)">
                Geri Dön
              </b-button>
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import CardComponent from '@/components/CardComponent'
import { PermissionService, RoleService as Service } from '@/services'
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'roleCreateOrUpdate',
  components: { CardComponent, Treeselect },
  props: ['modal', 'id'],
  data () {
    return {
      isLoading: false,
      form: { id: null, name: null, permissions: [] },
      permissions: []
    }
  },
  mounted () {
    this.getPermissions()
    this.form.id = this.$route.params.id || this.id || null
    if (this.form.id) this.getData()
  },
  methods: {
    getData () {
      Service.edit(this.form.id).then(({ data }) => this.form = data)
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    getPermissions () {
      PermissionService.all().then(({ data }) => this.permissions = data)
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    save () {
      Service[this.form.id ? 'update' : 'create'](this.form)
          .then(() => {
            this.$buefy.toast.open({
              message: this.$route.params.id ? 'Güncellendi' : 'Eklendi',
              type: 'is-success'
            })
            this.reset()
            if (this.modal)
              this.$emit('close')
            else
              this.$router.go(-1)
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })
    }
  }
}
</script>
